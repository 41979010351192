body{
    overflow-y: hidden;
}
.App{
    overflow: hidden;
    text-align: center;
}
.box{
    text-align: center;
    border: 5px solid #091a2d;
    height: 88.5vh;
}
.grid-box {
    margin: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vh;
  }
  .grid-box-b {
    margin: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2vh;
  }
  .grid-comp {
    height: 41vh;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .value{
    color:#00be00;
    font-size: 80px;
}
  @media only screen and (max-width: 768px) {
      .grid-box, .grid-box-b {    
    grid-template-columns: 1fr;
  }

.grid-comp, .grid-comp-b {
    height: 15vh;
}
.value{
    color:#00be00;
    font-size: 40px;
    margin: 0;
}
}
@media only screen and (max-width: 1100px) {
    .title{
        font-size: 25px!important;
        padding: 10px!important;
    }
}
@media only screen  and (min-width:1100px) and (max-width:1650px) {
  .title{
    font-size: 35px!important;
    padding: 0px!important;
}
.value{
  font-size: 60px;
  margin: 0;
}
}
.title{
    font-size: 40px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    background-color: #e4e4e4;
    padding: 35px;
    margin: 0;
}

